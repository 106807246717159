export default {
  'home.title': '主页',
  'home.share': '分享',
  'home.download': '下载',
  'home.download_sample_book': '下载样书',
  'home.select': '选择',
  'home.cancel': '取消',
  'home.select_all': '选择所有',
  'home.deselect_all': '取消选择所有',
  'home.export': '导出',
  'home.import': '导入',
  'home.upload': '上传',
  'home.delete': '删除',

  'toc.title': '目录',
  'toc.library': '图书馆',

  'search.title': '搜索',
  'search.files.result': '{n} 个结果在 {m} 个文件中',

  'annotation.title': '标注',
  'annotation.definitions': '定义',
  'annotation.annotations': '标注',
  'annotation.copy_as_markdown': '复制为 Markdown',

  'image.title': '图片',

  'timeline.title': '时间线',

  'typography.title': '排版',
  'typography.scope.book': '书籍',
  'typography.scope.global': '全局',
  'typography.page_view': '视图',
  'typography.page_view.single_page': '单页',
  'typography.page_view.double_page': '双页',
  'typography.font_family': '字体',
  'typography.font_size': '字号',
  'typography.font_weight': '字重',
  'typography.line_height': '行高',
  'typography.zoom': '缩放',

  'theme.title': '颜色主题',
  'theme.source_color': '源色',
  'theme.background_color': '背景色',

  'settings.title': '设置',
  'settings.language': '语言',
  'settings.color_scheme': '颜色模式',
  'settings.color_scheme.system': '系统',
  'settings.color_scheme.light': '浅色',
  'settings.color_scheme.dark': '深色',
  'settings.synchronization.title': '同步',
  'settings.synchronization.authorize': '授权',
  'settings.synchronization.unauthorize': '取消授权',
  'settings.cache': '缓存',
  'settings.cache.clear': '清除',

  'menu.copy': '复制',
  'menu.search_in_book': '书内搜索',
  'menu.annotate': '标注',
  'menu.define': '定义',
  'menu.undefine': '取消定义',
  'menu.create': '创建',
  'menu.delete': '删除',
  'menu.update': '更新',

  'action.expand_all': '展开全部',
  'action.collapse_all': '折叠全部',
  'action.close': '关闭',
  'action.clear': '清除',
  'action.step_down': '减少',
  'action.step_up': '增加',

  untitled: '未标题',
} as const
